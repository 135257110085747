<template>
    <div :class="`${$r.prefix}swiper`"
         ref="swiper"
    >
        <slot name="left" :left="left"></slot>
        <slot name="right" :right="right"></slot>
        <div class="swiper-container"
             ref="container"
             :class="{
            'in-move':inMove
             }"
             v-touch="{
                  'end':end,
                  'move':move
                 }"
             :style="{'transform':'translate3d('+x+'px,0,0)'}">
            <div class="swiper-item"
                 draggable="false"
                 :style="{'min-width':itemWidth&&itemWidth+'px','width':itemWidth&&itemWidth+'px'}"
                 v-for="(item,i) in items" :key="i">
                <slot :item="item" :index="i">{{item}}</slot>
            </div>
        </div>
    </div>
</template>

<script>
    import Touch from "../../directive/touch";

    export default {
        name: 'r-swiper',
        directives: {
            'touch': Touch
        },
        props: {
            items: Array,
            widthItem: Number,
            numShow: [Number, Object]
        },
        data() {
            return {
                timer: null,
                inMove: false,
                x: 0,
                prePosition: 0,
                width: null
            }
        },
        mounted() {
            if (this.numShow && this.$refs.swiper) {
                if (typeof this.numShow === 'object') {
                    for (let name in this.numShow) {
                        if (this.$r.breakpoint[name]) {
                            this.width = this.$refs.swiper.getBoundingClientRect().width / this.numShow[name]
                            break
                        }
                    }
                } else {
                    this.width = this.$refs.swiper.getBoundingClientRect().width / this.numShow
                }
            }
        },
        computed: {
            itemWidth() {
                if (this.width) {
                    return this.width
                }
                if (this.widthItem) {
                    return this.widthItem
                }

                return false
            }
        },
        methods: {
            right(x = null) {
                if (x === null) {
                    x = this.itemWidth || 200
                }
                this.timer = setTimeout(() => {
                    this.inMove = true
                    this.x = this.prePosition - x
                    this.end()
                    clearTimeout(this.timer)
                }, 50)
            }, left(x = null) {
                if (x === null) {
                    x = this.itemWidth || 200
                }
                this.timer = setTimeout(() => {
                    this.inMove = true
                    this.x = this.prePosition + x
                    this.end()
                    clearTimeout(this.timer)
                }, 50)
            }, move(e) {
                this.timer = setTimeout(() => {
                    this.inMove = true
                    this.x = this.prePosition + e.goX
                    clearTimeout(this.timer)
                }, 50)
            },
            end(e) {
                setTimeout(() => {

                    this.inMove = false
                    const r = this.$r.rtl ? 1 : -1

                    if (this.$refs.swiper && this.$refs.swiper.scrollWidth + this.x * r > this.$refs.container.scrollWidth) {
                        this.x = (this.$refs.container.scrollWidth - this.$refs.swiper.scrollWidth) * r
                    }
                    if (this.itemWidth) {
                        this.x = this.prePosition + Math.round((this.x - this.prePosition) / this.itemWidth) * this.itemWidth
                    }
                    this.prePosition = this.x

                    if (this.x * r < 0) {
                        this.x = 0
                        this.prePosition = 0
                    }
                }, 60)

            }
        }
    }
</script>
<style lang="scss">
    @import "../../style/include";

    .#{$prefix}swiper {
        width: 100%;
        max-width: 100%;
        height: 100%;
        overflow: hidden;

        .swiper-container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            &:not(.in-move) {
                transition: .3s all ease-in-out;
            }
            &.in-move{
                .swiper-item {
                    pointer-events: none;
                }
            }
        }

        .swiper-item {
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -ms-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);

            backface-visibility: hidden;
            -webkit-tap-highlight-color: transparent;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            a{
                -webkit-user-drag: none;
            }
        }

    }
</style>