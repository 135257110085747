<template>
    <component :is="toolbar">
        <template #search="{item}">
            <slot name="search" :item="item"></slot>
        </template>
    </component>
</template>

<script>
    import '../scss/toolbar.scss'
    import {defineAsyncComponent} from 'vue'

    export default {
        name: 'r-toolbar',
        computed: {
            isMobile() {
                return this.$r.breakpoint.mdAndDown
            },
            toolbar() {
                if (this.isMobile) {
                    return defineAsyncComponent(() => {
                        return new Promise(resolve => {
                            resolve(
                                import("./mobile")
                            );
                        });
                    });
                } else {
                    return defineAsyncComponent(() => {
                        return new Promise(resolve => {
                            resolve(
                                import("./laptop")
                            );
                        });
                    });
                }
            }
        }
    }
</script>
