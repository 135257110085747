<template>
    <div :class="`${$r.prefix}map-select`">
        <r-map @update:model-value="emit" search-box @map="handleMapEvent" @leaflet="handleLEvent"
               v-bind="$slots"></r-map>
    </div>
</template>
<script>
    export default {
        name: 'r-map-select',
        data() {
            return {
                map: null,
                L: null,
                marker: null,
                center: []
            }
        },
        methods: {
            handleLEvent(e) {
                this.L = e
            },
            handleMapEvent(e) {
                this.map = e
                this.selection()
            },
            selection() {
                this.$emit("update:modelValue", this.center);
                this.marker = new this.L.Marker(this.center);
                this.map.addLayer(this.marker);
                this.marker.bindPopup(this.$t("map_move_to_location",'renusify')).openPopup();

            },
            emit(e) {
                this.center = e
                this.$emit("update:modelValue", this.center);
                if (this.marker) {
                    this.marker.setLatLng(this.center);
                    this.map.closePopup();
                }

            }
        }
    }
</script>