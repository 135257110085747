<template>
    <r-input :class="`${$r.prefix}unit-input`" :active="active"
             :model-value="lazyValue"
             @click.prevent="handleClick">
        <input :autofocus="autofocus"
               :type="type"
               @focusin="active=true"
               @focusout="active=false"
               @input="emit"
               autocomplete="no"
               ref="input"
               v-model="lazyValue"
        />
        <r-select :items="units" v-model="unit" @update:model-value="emit" hide justValue :translate="translate"
                  firstSelect></r-select>
    </r-input>
</template>
<script>
    export default {
        name: 'r-unit-input',
        props: {
            type: {
                type: String,
                default: 'text'
            },
            units: {
                type: Array,
                required: true
            },
            modelValue: {
                type: Object, default: () => {
                    return {
                        value: null,
                        unit: null
                    }
                }
            },
            autofocus: Boolean,
            translate: Boolean
        },

        data() {
            return {
                lazyValue: this.modelValue.value,
                active: false,
                unit: null
            }
        },
        watch: {
            modelValue() {
                this.lazyValue = this.modelValue.value
            }
        },
        methods: {
            handleClick(e) {
                this.$refs.input.focus()
            },
            emit() {
                this.$emit('update:modelValue', {value: this.lazyValue, unit: this.unit})
            }
        }
    }

</script>
<style lang="scss">
    @import "../../style/include";

    .#{$prefix}unit-input {
        @include light() {
            .unit-select {
                border-color: var(--color-border-light) !important;
            }
            &.input-disabled {
                .unit-select {
                    border-color:var(--color-text-disabled-light) !important;
                }
            }
        }
        @include dark() {
            .unit-select {
                border-color: var(--color-border-dark) !important;
            }
            &.input-disabled {
                .unit-select {
                    border-color:var(--color-text-disabled-dark) !important;
                }
            }
        }

        input {
            width: 70%;
        }

        .#{$prefix}select-container {
            width: calc(30% + 16px);
            @include rtl() {
                border-right: 1px solid;
            }

            @include ltr() {
                border-left: 1px solid;
            }

            .card-select {
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
        }
    }
</style>