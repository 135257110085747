<template>
    <i :class="classes"></i>

</template>

<script>
    import './style.scss'

    export default {
        name: 'index',
        props: {
            disabled: Boolean,
            exact: Boolean
        },
        computed: {
            classes() {
                let a = {
                    'icon-disabled': this.disabled,
                    'exact-icon': this.exact,
                    'mdi':true
                }
                a[`${this.$r.prefix}icon`]=true
                return a
            }
        }
    }
</script>
