<template>
  <div>
    <ul class="colors">
      <li
        v-for="item in colorsDefault"
        :key="item"
        class="item"
        @click="selectColor(item)"
      >
        <div :style="{ background: `url(${imgAlphaBase64})` }" class="alpha" />
        <div :style="{ background: item }" class="color" />
      </li>
    </ul>
  </div>
</template>

<script >
import {  onUnmounted, ref } from 'vue'
import { createAlphaSquare } from './composible'
export default
        {
  name: 'ColorPicker',
  props: {
    colr: {
      type: String,
      default: '#000000',
    },
    colorsDefault: {
      type: Array,
      default: () => [],
    }
  },
  emits: ['selectColor'],
  setup(props, { emit }) {
    const color = ref()
    const imgAlphaBase64 = ref()

    imgAlphaBase64.value = createAlphaSquare(4).toDataURL()

    function selectColor(color) {
      emit('selectColor', color)
    }

    return {
      color,
      imgAlphaBase64,
      selectColor,
    }
  },
}
</script>
<style lang="scss">
.colors {
  padding: 0;
  margin: 0;
  .item {
    position: relative;
    width: 16px;
    height: 16px;
    margin: 10px 0 0 10px;
    border-radius: 3px;
    box-sizing: border-box;
    vertical-align: top;
    display: inline-block;
    transition: all 0.1s;
    cursor: pointer;
    &:nth-child(8n + 1) {
      margin-left: 0;
    }
    &:hover {
      transform: scale(1.4);
    }
    .alpha {
      height: 100%;
      border-radius: 4px;
    }
    .color {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px;
    }
  }
}
</style>
