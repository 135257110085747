class DateTime {
    format = {
        narrow: {
            weekday: 'narrow'
        },
        day: {
            day: 'numeric'
        },
        month: {
            month: 'short'
        },
        year: {
            year: 'numeric'
        },
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        medium: {
            year: 'numeric', month: 'numeric', day: 'numeric'
        },
        long: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            weekday: 'short',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        }
    }

    langs = {
        en: {
            localizeName: 'en-US',
            timeZone: 'America/New_York'
        },
        fa: {
            localizeName: 'fa-IR',
            timeZone: 'Asia/Tehran',
        }
    };

    set_lang(name,lang) {
        if(!lang['localizeName']||!lang['timeZone']){
            console.error('new Lang Must contain localizeName and timeZone (en: {\n' +
                '            localizeName: \'en-US\',\n' +
                '            timeZone: \'America/New_York\'\n' +
                '        })')
            return
        }
        this.langs[name]=lang
    }

    set_format(value) {
        Object.assign(this.format, this.format, value)
    }

    formatLocal(value, type = 'long', local = null) {
        if (local === null && window.app) {
            local = window.app.$r.lang
        }
        var opt = this.format[type];
        opt.hour12 = false;
        opt.timeZone = this.langs[local].timeZone;
        try {
            return new Intl.DateTimeFormat(this.langs[local].localizeName, opt).format(value);
        } catch (e) {
            console.error(e)
            return value
        }
    }
}

export default new DateTime();
