<template>
    <r-select :label="$t('state','renusify')"
              v-model="state"
              @update:model-value="emit"
              :items="Object.keys(states)"
              :rules="required?['required']:[]"
              :tile="tile"
              justValue>

    </r-select>
    <r-select v-if="state"
              @update:model-value="emit"
              :label="$t('city','renusify')"
              v-model="city"
              :items="states[state]"
              :rules="required?['required']:[]"
              :tile="tile"
              justValue>

    </r-select>
    <r-text-area v-if="city && !hideAddress" @update:model-value="emit"
                 :label="$t('address','renusify')"
                 v-model="address"
                 :tile="tile"
                 :rules="required?['required']:[]"></r-text-area>
</template>
<script>

    import RTextArea from "./text-area";
    import RSelect from "./select";

    export default {
        name: 'r-address',
        inheritAttrs: false,
        components: {RSelect, RTextArea},
        props: {
            required: Boolean,
            hideAddress: Boolean,
            tile: Boolean,
            modelValue: Object
        },
        data() {
            return {
                state: this.modelValue?this.modelValue.state:null,
                city: this.modelValue?this.modelValue.city:null,
                address: this.modelValue?this.modelValue.address:null,
                states: {
                    "اردبیل": ["اردبیل", "اصلاندوز", "آبی بیگلو", "بیله سوار", "پارس آباد", "تازه کند", "تازه کندانگوت", "جعفرآباد", "خلخال", "رضی", "سرعین", "عنبران", "فخرآباد", "کلور", "کوراییم", "گرمی", "گیوی", "لاهرود", "مرادلو", "مشگین شهر", "نمین", "نیر", "هشتجین", "هیر"],
                    "اصفهان": ["ابریشم", "ابوزیدآباد", "اردستان", "اژیه", "اصفهان", "افوس", "انارک", "ایمانشهر", "آران وبیدگل", "بادرود", "باغ بهادران", "بافران", "برزک", "برف انبار", "بوئین ومیاندشت", "بهاران شهر", "بهارستان", "پیربکران", "تودشک", "تیران", "جندق", "جوزدان", "جوشقان وکامو", "چادگان", "چرمهین", "چمگردان", "حبیب آباد", "حسن آباد", "حنا", "خالدآباد", "خمینی شهر", "خوانسار", "خور", "خوراسگان", "خورزوق", "داران", "دامنه", "درچه پیاز", "دستگرد", "دولت آباد", "دهاقان", "دهق", "دیزیچه", "رزوه", "رضوانشهر", "زاینده رود", "زرین شهر", "زواره", "زیباشهر", "سده لنجان", "سفیدشهر", "سگزی", "سمیرم", "شاپورآباد", "شاهین شهر", "شهرضا", "طالخونچه", "عسگران", "علویچه", "فرخی", "فریدونشهر", "فلاورجان", "فولادشهر", "قمصر", "قهجاورستان", "قهدریجان", "کاشان", "کرکوند", "کلیشادوسودرجان", "کمشچه", "کمه", "کوشک", "کوهپایه", "کهریزسنگ", "گرگاب", "گزبرخوار", "گلپایگان", "گلدشت", "گلشن", "گلشهر", "گوگد", "لای بید", "مبارکه", "محمدآباد", "مشکات", "منظریه", "مهاباد", "میمه", "نائین", "نجف آباد", "نصرآباد", "نطنز", "نوش آباد", "نیاسر", "نیک آباد", "ورزنه", "ورنامخواست", "وزوان", "ونک", "هرند"],
                    "البرز": ["اشتهارد", "آسارا", "تنکمان", "چهارباغ", "سیف آباد", "شهرجدیدهشتگرد", "طالقان", "کرج", "کمال شهر", "کوهسار", "گرمدره", "ماهدشت", "محمدشهر", "مشکین دشت", "نظرآباد", "هشتگرد"],
                    "ایلام": ["ارکواز", "ایلام", "ایوان", "آبدانان", "آسمان آباد", "بدره", "پهله", "توحید", "چوار", "دره شهر", "دلگشا", "دهلران", "زرنه", "سراب باغ", "سرابله", "صالح آباد", "لومار", "مورموری", "موسیان", "مهران", "میمه "],
                    "آذربایجان شرقی": ["اسکو", "اهر", "ایلخچی", "آبش احمد", "آذرشهر", "آقکند", "باسمنج", "بخشایش", "بستان آباد", "بناب", "بناب جدید", "تبریز", "ترک", "ترکمانچای", "تسوج", "تیکمه داش", "جلفا", "خاروانا", "خامنه", "خراجو", "خسروشهر", "خمارلو", "خواجه", "دوزدوزان", "زرنق", "زنوز", "سراب", "سردرود", "سیس", "سیه رود", "شبستر", "شربیان", "شرفخانه", "شندآباد", "شهرجدیدسهند", "صوفیان", "عجب شیر", "قره آغاج", "کشکسرای", "کلوانق", "کلیبر", "کوزه کنان", "گوگان", "لیلان", "مراغه", "مرند", "ملکان", "ممقان", "مهربان", "میانه", "نظرکهریزی", "وایقان", "ورزقان", "هادیشهر", "هریس", "هشترود", "هوراند", "یامچی"],
                    "آذربایجان غربی": ["ارومیه", "اشنویه", "ایواوغلی", "آواجیق", "باروق", "بازرگان", "بوکان", "پلدشت", "پیرانشهر", "تازه شهر", "تکاب", "چهاربرج", "خلیفان", "خوی", "دیزج دیز", "ربط", "سردشت", "سرو", "سلماس", "سیلوانه", "سیمینه", "سیه چشمه", "شاهین دژ", "شوط", "فیرورق", "قره ضیاءالدین", "قطور", "قوشچی", "کشاورز", "گردکشانه", "ماکو", "محمدیار", "محمودآباد", "مهاباد ", "میاندوآب", "میرآباد", "نالوس", "نقده", "نوشین"],
                    "بوشهر": ["امام حسن", "انارستان", "اهرم", "آبپخش", "آبدان", "برازجان", "بردخون", "بردستان", "بندردیر", "بندردیلم", "بندرریگ", "بندرکنگان", "بندرگناوه", "بنک", "بوشهر", "تنگ ارم", "جم", "چغادک", "خارک", "خورموج", "دالکی", "دلوار", "ریز", "سعدآباد", "سیراف", "شبانکاره", "شنبه", "عسلویه", "کاکی", "کلمه", "نخل تقی", "وحدتیه"],
                    "تهران": ["ارجمند", "اسلامشهر", "اندیشه", "آبسرد", "آبعلی", "باغستان", "باقرشهر", "بومهن", "پاکدشت", "پردیس", "پیشوا", "تجریش", "تهران", "جوادآباد", "چهاردانگه", "حسن آباد ", "دماوند", "رباط کریم", "رودهن", "ری", "شاهدشهر", "شریف آباد", "شهریار", "صالح آباد ", "صباشهر", "صفادشت", "فردوسیه", "فرون آباد", "فشم", "فیروزکوه", "قدس", "قرچک", "کهریزک", "کیلان", "گلستان", "لواسان", "ملارد", "نسیم شهر", "نصیرآباد", "وحیدیه", "ورامین"],
                    "چهارمحال وبختیاری": ["اردل", "آلونی", "باباحیدر", "بروجن", "بلداجی", "بن", "جونقان", "چلگرد", "سامان", "سفیددشت", "سودجان", "سورشجان", "شلمزار", "شهرکرد", "طاقانک", "فارسان", "فرادنبه", "فرخ شهر", "کیان", "گندمان", "گهرو", "لردگان", "مال خلیفه", "ناغان", "نافچ", "نقنه", "هفشجان"],
                    "خراسان جنوبی": ["ارسک", "اسدیه", "اسفدن", "اسلامیه", "آرین شهر", "آیسک", "بشرویه", "بیرجند", "حاجی آباد", "خضری دشت بیاض", "خوسف", "زهان", "سرایان", "سربیشه", "سه قلعه", "شوسف", "طبس مسینا", "فردوس", "قائن", "قهستان", "گزیک", "محمد شهر", "مود", "نهبندان", "نیمبلوک"],
                    "خراسان رضوی": ["احمدآبادصولت", "انابد", "باجگیران", "باخرز", "بار", "بایگ", "بجستان", "بردسکن", "بیدخت", "تایباد", "تربت جام", "تربت حیدریه", "جغتای", "جنگل", "چاپشلو", "چکنه", "چناران", "خرو", "خلیل آباد", "خواف", "داورزن", "درگز", "درود", "دولت آباد ", "رباط سنگ", "رشتخوار", "رضویه", "روداب", "ریوش", "سبزوار", "سرخس", "سفیدسنگ", "سلامی", "سلطان آباد", "سنگان", "شادمهر", "شاندیز", "ششتمد", "شهرآباد", "شهرزو", "صالح آباد  ", "طرقبه", "عشق آباد", "فرهادگرد", "فریمان", "فیروزه", "فیض آباد", "قاسم آباد", "قدمگاه", "قلندرآباد", "قوچان", "کاخک", "کاریز", "کاشمر", "کدکن", "کلات", "کندر", "گلمکان", "گناباد", "لطف آباد", "مزدآوند", "مشهد", "مشهدریزه", "ملک آباد", "نشتیفان", "نصر آباد", "نقاب", "نوخندان", "نیشابور", "نیل شهر", "همت آباد", "یونسی"],
                    "خراسان شمالی": ["اسفراین", "ایور", "آشخانه", "بجنورد", "پیش قلعه", "تیتکانلو", "جاجرم", "حصارگرمخان", "درق", "راز", "سنخواست", "شوقان", "شیروان", "صفی آباد", "فاروج", "قاضی", "گرمه", "لوجلی"],
                    "خوزستان": ["اروندکنار", "الوان", "امیدیه", "اندیمشک", "اهواز", "ایذه", "آبادان", "آغاجاری", "باغ ملک", "بستان", "بندرامام خمینی", "بندرماهشهر", "بهبهان", "ترکالکی", "جایزان", "جنت مکان", "چغامیش", "چمران", "چوئبده", "حر", "حسینیه", "حمزه", "حمیدیه", "خرمشهر", "دارخوین", "دزآب", "دزفول", "دهدز", "رامشیر", "رامهرمز", "رفیع", "زهره", "سالند", "سردشت ", "سماله", "سوسنگرد", "شادگان", "شاوور", "شرافت", "شوش", "شوشتر", "شیبان", "صالح شهر", "صالح مشطط", "صفی آباد ", "صیدون", "قلعه تل", "قلعه خواجه", "گتوند", "گوریه", "لالی", "مسجدسلیمان", "مشراگه", "مقاومت", "ملاثانی", "میانرود", "میداود", "مینوشهر", "ویس", "هفتگل", "هندیجان", "هویزه"],
                    "زنجان": ["ابهر", "ارمغانخانه", "آب بر", "چورزق", "حلب", "خرمدره", "دندی", "زرین آباد", "زرین رود", "زنجان", "سجاس", "سلطانیه", "سهرورد", "صائین قلعه", "قیدار", "گرماب", "ماه نشان", "هیدج"],
                    "سمنان": ["امیریه", "ایوانکی", "آرادان", "بسطام", "بیارجمند", "دامغان", "درجزین", "دیباج", "سرخه", "سمنان", "شاهرود", "شهمیرزاد", "کلاته خیج", "گرمسار", "مجن", "مهدی شهر", "میامی"],
                    "سیستان وبلوچستان": ["ادیمی", "اسپکه", "ایرانشهر", "بزمان", "بمپور", "بنت", "بنجار", "پیشین", "جالق", "چاه بهار", "خاش", "دوست محمد", "راسک", "زابل", "زابلی", "زاهدان", "زرآباد", "زهک", "سراوان", "سرباز", "سوران", "سیرکان", "علی اکبر", "فنوج", "قصرقند", "کنارک", "گشت", "گلمورتی", "محمدان", "محمد آباد", "محمدی", "میرجاوه", "نصرت آباد", "نگور", "نوک آباد", "نیک شهر", "هیدوج"],
                    "فارس": ["اردکان", "ارسنجان", "استهبان", "اسیر", "اشکنان", "افزر", "اقلید", "امام شهر", "اوز", "اهل", "ایج", "ایزدخواست", "آباده", "آباده طشک", "باب انار", "بالاده", "بنارویه", "بوانات", "بهمن", "بیرم", "بیضا", "جنت شهر", "جویم", "جهرم", "حاجی آباد ", "حسامی", "حسن آباد  ", "خانه زنیان", "خاوران", "خرامه", "خشت", "خنج", "خور ", "خومه زار", "داراب", "داریان", "دبیران", "دژکرد", "دوبرجی", "دوزه", "دهرم", "رامجرد", "رونیز", "زاهدشهر", "زرقان", "سده", "سروستان", "سعادت شهر", "سورمق", "سیدان", "ششده", "شهر جدید صدرا", "شهرپیر", "شیراز", "صغاد", "صفاشهر", "علامرودشت", "عمادده", "فدامی", "فراشبند", "فسا", "فیروزآباد", "قادرآباد", "قائمیه", "قطب آباد", "قطرویه", "قیر", "کارزین", "کازرون", "کامفیروز", "کره ای", "کنارتخته", "کوار", "کوهنجان", "گراش", "گله دار", "لار", "لامرد", "لپوئی", "لطیفی", "مبارک آباد", "مرودشت", "مشکان", "مصیری", "مهر", "میمند", "نوبندگان", "نوجین", "نودان", "نورآباد", "نی ریز", "وراوی", "هماشهر"],
                    "قزوین": ["ارداق", "اسفرورین", "اقبالیه", "الوند", "آبگرم", "آبیک", "آوج", "بوئین زهرا", "بیدستان", "تاکستان", "خاکعلی", "خرمدشت", "دانسفهان", "رازمیان", "سگزآباد", "سیردان", "شال", "شریفیه", "ضیاءآباد", "قزوین", "کوهین", "محمدیه", "محمودآبادنمونه", "معلم کلایه", "نرجه"],
                    "قم": ["جعفریه", "دستجرد", "سلفچگان", "قم", "قنوات", "کهک"],
                    "کردستان": ["آرمرده", "بابارشانی", "بانه", "بلبان آباد", "بوئین سفلی", "بیجار", "چناره", "دزج", "دلبران", "دهگلان", "دیواندره", "زرینه", "سروآباد", "سریش آباد", "سقز", "سنندج", "شویشه", "صاحب", "قروه", "کامیاران", "کانی دینار", "کانی سور", "مریوان", "موچش", "یاسوکند"],
                    "کرمان": ["اختیارآباد", "ارزوئیه", "امین شهر", "انار", "اندوهجرد", "باغین", "بافت", "بردسیر", "بروات", "بزنجان", "بم", "بهرمان", "پاریز", "جبالبارز", "جوپار", "جوزم", "جیرفت", "چترود", "خاتون آباد", "خانوک", "خورسند", "درب بهشت", "دوساری", "دهج", "رابر", "راور", "راین", "رفسنجان", "رودبار", "ریحان شهر", "زرند", "زنگی آباد", "زیدآباد", "سرچشمه", "سیرجان", "شهداد", "شهربابک", "صفائیه", "عنبرآباد", "فاریاب", "فهرج", "قلعه گنج", "کاظم آباد", "کرمان", "کشکوئیه", "کوهبنان", "کهنوج", "کیانشهر", "گلباف", "گلزار", "لاله زار", "ماهان", "محمد آباد ", "محی آباد", "مردهک", "منوجان", "نجف شهر", "نرماشیر", "نظام شهر", "نگار", "نودژ", "هجدک", "هماشهر ", "یزدان شهر"],
                    "کرمانشاه": ["ازگله", "اسلام آبادغرب", "باینگان", "بیستون", "پاوه", "تازه آباد", "جوانرود", "حمیل", "رباط", "روانسر", "سرپل ذهاب", "سرمست", "سطر", "سنقر", "سومار", "شاهو", "صحنه", "قصرشیرین", "کرمانشاه", "کرندغرب", "کنگاور", "کوزران", "گهواره", "گیلانغرب", "میان راهان", "نودشه", "نوسود", "هرسین", "هلشی"],
                    "کهگیلویه وبویراحمد": ["باشت", "پاتاوه", "چرام", "چیتاب", "دوگنبدان", "دهدشت", "دیشموک", "سوق", "سی سخت", "قلعه رئیسی", "گراب سفلی", "لنده", "لیکک", "مادوان", "مارگون", "یاسوج"],
                    "گلستان": ["انبارآلوم", "اینچه برون", "آزادشهر", "آق قلا", "بندرگز", "ترکمن", "جلین", "خان ببین", "دلند", "رامیان", "سرخنکلاته", "سیمین شهر", "علی آباد", "فاضل آباد", "کردکوی", "کلاله", "گالیکش", "گرگان", "گمیش تپه", "گنبد کاووس", "مراوه تپه", "مینودشت", "نگین شهر", "نوده خاندوز", "نوکنده"],
                    "گیلان": ["احمدسرگوراب", "اسالم", "اطاقور", "املش", "آستارا", "آستانه اشرفیه", "بازارجمعه", "بره سر", "بندرانزلی", "پره سر", "توتکابن", "جیرنده", "چابکسر", "چاف وچمخاله", "چوبر", "حویق", "خشکبیجار", "خمام", "دیلمان", "رانکوه", "رحیم آباد", "رستم آباد", "رشت", "رضوانشهر ", "رودبار ", "رودبنه", "رودسر", "سنگر", "سیاهکل", "شفت", "شلمان", "صومعه سرا", "فومن", "کلاچای", "کوچصفهان", "کومله", "کیاشهر", "گوراب زرمیخ", "لاهیجان", "لشت نشاء", "لنگرود", "لوشان", "لولمان", "لوندویل", "لیسار", "ماسال", "ماسوله", "مرجقل", "منجیل", "واجارگاه", "هشتپر"],
                    "لرستان": ["ازنا", "اشترینان", "الشتر", "الیگودرز", "بروجرد", "پلدختر", "چالانچولان", "چغلوندی", "چقابل", "خرم آباد", "درب گنبد", "دورود", "زاغه", "سپیددشت", "سراب دوره", "شول آباد", "فیروز آباد", "کونانی", "کوهدشت", "گراب", "معمولان", "مؤمن آباد", "نور آباد", "ویسیان", "هفت چشمه"],
                    "مازندران": ["امیرکلا", "ایزدشهر", "آلاشت", "آمل", "بابل", "بابلسر", "بلده", "بهشهر", "بهنمیر", "پل سفید", "پول", "تنکابن", "جویبار", "چالوس", "چمستان", "خرم آباد ", "خلیل شهر", "خوش رودپی", "دابودشت", "رامسر", "رستمکلا", "رویان", "رینه", "زرگر محله", "زیرآب", "ساری", "سرخرود", "سلمان شهر", "سورک", "شیرگاه", "شیرود", "عباس آباد", "فریدونکنار", "فریم", "قائم شهر", "کتالم وسادات شهر", "کلارآباد", "کلاردشت", "کله بست", "کوهی خیل", "کیاسر", "کیاکلا", "گتاب", "گزنک", "گلوگاه", "محمود آباد", "مرزن آباد", "مرزیکلا", "نشتارود", "نکا", "نور", "نوشهر"],
                    "مرکزی": ["اراک", "آستانه", "آشتیان", "پرندک", "تفرش", "توره", "جاورسیان", "خشکرود", "خمین", "خنداب", "داودآباد", "دلیجان", "رازقان", "زاویه", "ساروق", "ساوه", "سنجان", "شازند", "شهرجدیدمهاجران", "غرق آباد", "فرمهین", "قورچی باشی", "کرهرود", "کمیجان", "مأمونیه", "محلات", "میلاجرد", "نراق", "نوبران", "نیمور", "هندودر"],
                    "هرمزگان": ["ابوموسی", "بستک", "بندرجاسک", "بندرچارک", "بندرعباس", "بندرلنگه", "بیکاه", "پارسیان", "تخت", "جناح", "حاجی آباد  ", "خمیر", "درگهان", "دهبارز", "رویدر", "زیارتعلی", "سردشت بشاگرد", "سرگز", "سندرک", "سوزا", "سیریک", "فارغان", "فین", "قشم", "قلعه قاضی", "کنگ", "کوشکنار", "کیش", "گوهران", "میناب", "هرمز", "هشتبندی"],
                    "همدان": ["ازندریان", "اسدآباد", "برزول", "بهار", "تویسرکان", "جورقان", "جوکار", "دمق", "رزن", "زنگنه", "سامن", "سرکان", "شیرین سو", "صالح آباد   ", "فامنین", "فرسفج", "فیروزان", "قروه در جزین", "قهاوند", "کبودرآهنگ", "گل تپه", "گیان", "لالجین", "مریانج", "ملایر", "نهاوند", "همدان"],
                    "یزد": ["ابرکوه", "احمدآباد", "اردکان ", "اشکذر", "بافق", "بفروئیه", "بهاباد", "تفت", "حمیدیا", "خضرآباد", "دیهوک", "زارچ", "شاهدیه", "طبس", "عشق آباد ", "عقدا", "مروست", "مهردشت", "مهریز", "میبد", "ندوشن", "نیر ", "هرات", "یزد"]
                },
            }
        },
        watch: {
            modelValue() {
                this.state = this.modelValue.state
                this.city = this.modelValue.city
                this.address = this.modelValue.address
            }
        },
        methods: {
            emit() {
                setTimeout(()=>{
                   this.$emit('update:model-value', {
                    state: this.state,
                    city: this.city,
                    address: this.address
                })
                },10)
            }
        }
    }

</script>
