<template>
    <div :class="`${$r.prefix}call`">
        <div class="video-holder" :style="{width:width+'px',height:height+'px'}">
            <video v-show="start" :class="{'mini-video':meSmall,'full-video':!meSmall}" muted ref="selfView" autoplay
                   playsinline></video>
            <video v-show="start" :class="{'mini-video':!meSmall,'full-video':meSmall}" ref="remoteView" autoplay
                   playsinline></video>
        </div>
        <div class="btn-holder d-flex h-center px-5 pt-2" v-if="partners.length===2">
            <r-btn v-if="!start&&ringing" @click="stopChat" icon class="color-error">
                <r-icon v-html="$r.icons.video"></r-icon>
            </r-btn>
            <r-spacer v-if="!start&&ringing"></r-spacer>
            <r-btn v-if="!start" @click="startChat" icon class="color-success"
                   :class="{'animation-shake-2':ringing}">
                <r-icon v-html="$r.icons.video"></r-icon>
            </r-btn>
            <r-btn v-else @click="stopChat" icon class="color-error">
                <r-icon v-html="$r.icons.video"></r-icon>
            </r-btn>
        </div>
        <div v-else class="text-center">
            <div>{{$t('waiting_audience','renusify')}}</div>
            <r-btn @click="stopChat" icon class="color-error">
                <r-icon v-html="$r.icons.close"></r-icon>
            </r-btn>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'r-call',
        props: {
            recorded: Boolean,
            roomId: {type: String, required: true},
            wsUrl: {type: String, required: true},
            width: {type: Number, default: 300},
            height: {type: Number, default: 600},
        },
        data() {
            return {
                time_id: null,
                start: false,
                ringing: false,
                meSmall: false,
                mediaRecorder: null,
                signaling: null,
                peerConnection: null,
                MESSAGE_TYPE: {
                    SDP: 'SDP',
                    CANDIDATE: 'CANDIDATE',
                    FUNCTION: 'FUNCTION',
                },
                recordedBlobs: [],
                partners: []
            }
        },
        created() {
            this.connect()
            if (!this.$r.icons.video) {
                this.$r.icons.video = '<path fill="currentColor" d="M17 10.5V7a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-3.5l4 4v-11l-4 4Z"/>'
            }
        },
        computed: {
            getSupportedMimeTypes() {
                const possibleTypes = [
                    'video/webm;codecs=vp9,opus',
                    'video/webm;codecs=vp8,opus',
                    'video/webm;codecs=h264,opus',
                    'video/mp4;codecs=h264,aac',
                ];
                return possibleTypes.filter(mimeType => {
                    return MediaRecorder.isTypeSupported(mimeType);
                });
            }
        },
        methods: {
            startRecordingMulti(stream) {
                // src="https://www.webrtc-experiment.com/MultiStreamsMixer.js"
                this.$refs['selfView'].fullcanvas = true
                this.$refs['selfView'].width = this.width
                this.$refs['selfView'].height = this.height
                this.$refs['remoteView'].width = this.width
                this.$refs['remoteView'].height = this.height
                const mixer = new MultiStreamsMixer([this.$refs['selfView'].srcObject, this.$refs['remoteView'].srcObject]);
                this.mediaRecorder = new MediaRecorder(mixer.getMixedStream());
                mixer.startDrawingFrames();
                this.mediaRecorder.onstop = (event) => {
                    console.log('Recorder stopped');
                    this.download()
                };
                this.mediaRecorder.ondataavailable = this.handleDataAvailable;
                this.mediaRecorder.start();
            },
            startRecording(stream) {
                this.recordedBlobs = [];
                const mimeType = this.getSupportedMimeTypes[0]
                const options = {mimeType};

                try {
                    this.mediaRecorder = new MediaRecorder(stream, options);
                } catch (e) {
                    console.error('Exception while creating MediaRecorder:', e);
                    return;
                }

                this.mediaRecorder.onstop = (event) => {
                    console.log('Recorder stopped');
                    this.download()
                };
                this.mediaRecorder.ondataavailable = this.handleDataAvailable;
                this.mediaRecorder.start();
                console.log('MediaRecorder started');
            },
            download() {
                const blob = new Blob(this.recordedBlobs, {type: 'video/webm'});
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = this.roomId + '.webm';
                document.body.appendChild(a);
                a.click();
                setTimeout(() => {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 100);
            },
            handleDataAvailable(event) {
                if (event.data && event.data.size > 0) {
                    this.recordedBlobs.push(event.data);
                }
            },
            setData(a) {
                for (let key in a) {
                    if (this.$helper.ifHas(a, key)) {
                        this[key] = a[key]
                    }
                }
            },
            connect() {
                const WS_URL =
                    this.wsUrl + '/' +
                    this.roomId +
                    "?a=" +
                    this.$storage.get("auth.token");
                this.signaling = new WebSocket(WS_URL);

                this.signaling.onclose = () => {
                    this.dispose()
                    clearTimeout(this.time_id)
                    this.time_id = setTimeout(() => {
                        this.connect()
                        if (this.start) {
                            this.time_id = setTimeout(() => {
                                this.startChat()
                            }, 100)
                        }
                    }, 1000)
                }
                this.addMessageHandler();
            },
            startChat() {
                this.start = true
                this.sendMessage({
                    message_type: this.MESSAGE_TYPE.FUNCTION,
                    content: {'f': 'setData', 'a': {'ringing': true}}
                });

                try {
                    navigator.mediaDevices.getUserMedia({
                        audio: true,
                        video: {width: this.width, height: this.height}
                    }).then((stream) => {
                        this.peerConnection = this.createPeerConnection();
                        stream.getTracks().forEach(track => this.peerConnection.addTrack(track, stream));
                        this.$refs['selfView'].srcObject = stream;
                    })
                } catch (err) {
                    console.error('startChat: ' + err);
                }
            },

            createPeerConnection() {
                const peerConnection = new RTCPeerConnection({
                    iceServers: [{urls: 'stun:stun.stunprotocol.org'}],
                });

                peerConnection.onnegotiationneeded = async () => {
                    await this.createAndSendOffer();
                };

                peerConnection.onicecandidate = (iceEvent) => {
                    if (iceEvent && iceEvent.candidate) {
                        this.sendMessage({
                            message_type: this.MESSAGE_TYPE.CANDIDATE,
                            content: iceEvent.candidate,
                        });
                    }
                };

                peerConnection.ontrack = (event) => {
                    const video = this.$refs['remoteView'];
                    video.srcObject = event.streams[0];
                    if (this.recorded && this.mediaRecorder === null) {
                        this.startRecording(event.streams[0])
                    }

                    this.meSmall = true

                };

                return peerConnection;
            },

            addMessageHandler() {
                this.signaling.onmessage = async (message) => {
                    const data = JSON.parse(message.data);

                    if (!data) {
                        return;
                    }

                    const {message_type, content} = data;
                    content.sdp += '\n';
                    try {
                        if (this.start && message_type === this.MESSAGE_TYPE.CANDIDATE && content) {
                            await this.peerConnection.addIceCandidate(content);
                        } else if (this.start && message_type === this.MESSAGE_TYPE.SDP) {
                            if (content.type === 'offer') {
                                await this.peerConnection.setRemoteDescription(content);
                                const answer = await this.peerConnection.createAnswer();
                                await this.peerConnection.setLocalDescription(answer);
                                this.sendMessage({
                                    message_type: this.MESSAGE_TYPE.SDP,
                                    content: answer,
                                });
                            } else if (content.type === 'answer') {
                                await this.peerConnection.setRemoteDescription(content);
                            } else {
                                console.log('Unsupported SDP type.');
                            }
                        } else if (message_type === this.MESSAGE_TYPE.FUNCTION) {
                            if (content['a']) {
                                this[content['f']](content['a'])
                            } else {
                                this[content['f']]()
                            }
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }
            },

            sendMessage(message) {
                if (this.signaling !== null) {
                    try {
                        this.signaling.send(JSON.stringify(message));
                    } catch (e) {
                        console.error(message, e)
                    }
                }
            },

            createAndSendOffer() {
                this.peerConnection.createOffer().then((offer) => {
                    this.peerConnection.setLocalDescription(offer).then(() => {
                        this.sendMessage({
                            message_type: this.MESSAGE_TYPE.SDP,
                            content: offer
                        });
                    });
                })
            },
            stopChat() {

                this.start = false
                this.ringing = false

                this.dispose()
                this.sendMessage({
                    message_type: this.MESSAGE_TYPE.FUNCTION,
                    content: {'f': 'stopChat'}
                });
                this.$emit('stop-chat', true)

            },
            dispose() {
                this.meSmall = false
                if (this.$refs['selfView'] && this.$refs['selfView'].srcObject) {
                    this.$refs['selfView'].srcObject.getTracks().forEach(function (track) {
                        track.stop();
                    });
                    this.$refs['selfView'].srcObject = null;
                }
                if (this.peerConnection !== null) {
                    this.peerConnection.close()
                    this.peerConnection = null
                }
                if (this.mediaRecorder !== null) {
                    this.mediaRecorder.stop()
                    this.mediaRecorder = null
                }

            }
        },
        beforeUnmount() {
            clearTimeout(this.time_id)
            if (this.signaling !== null) {
                this.signaling.onclose = null;
                this.signaling.close();
                this.signaling = null;
            }
            this.dispose()
        }
    };
</script>
<style lang="scss">
    @import "../../style/_include";

    .#{$prefix}call {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .video-holder {
            position: relative;
            display: flex;
        }

        .mini-video {
            width: div(300px, 4);
            height: div(600px, 5);
            object-fit: cover;
            position: absolute;
            bottom: 5px;
            right: 5px;
            transition: .3s all ease-in-out;
            z-index: 1;
        }

        .full-video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            bottom: 0px;
            right: 0px;
        }

        .btn-holder {
            width: 200px;
        }
    }

</style>