<template>
  <div class="html2pdf__page-break"></div>
</template>

<script>
export default {
  name: "pageBreak",
  data() {
    return {};
  }
};
</script>
