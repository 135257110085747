<template>
  <r-input :active="active"
           :modelValue="lazyValue"
           @click.stop="handleClick()"
           class="text-area"
           :class="{'no-resize':noResize}"
  >

      <textarea @focusin="active=true"
                @focusout="active=false"
                @input="$emit('update:modelValue', lazyValue)"
                ref="input"
                :rows="rows"
                autocomplete="no"
                v-model="lazyValue"
      ></textarea>
  </r-input>
</template>
<script>

export default {
  name: 'r-text-area',
  props: {
      noResize:Boolean,
    modelValue: [String, Number],
      rows:{
        type:String,
          default:'7'
      }
  },

  data () {
    return {
      lazyValue: this.modelValue,
      active: false
    }
  },
  watch: {
    modelValue () {
      this.lazyValue = this.modelValue
    }
  },
  methods: {
    handleClick () {
      this.$refs.input.focus()
    }
  }
}

</script>
<style lang="scss">
     @import "../../style/include";
     .#{$prefix}text-area{
         .no-resize{
             resize: none;
         }
     }
</style>