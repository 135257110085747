<template>
  <div class="col">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'r-col'
}
</script>
