<template>
  <main :class="classes">
    <div class="content-wrap">
      <slot></slot>
    </div>
  </main>
</template>

<script>
import './style.scss'

export default {
  name: 'r-content',
  props:{
    belowHeader:Boolean,
    flipped:Boolean,
    moved:Boolean
  },
  computed:{
    classes(){
      let a={'flipped':this.flipped,'moved':this.moved,'below-header':this.belowHeader}
      a[`${this.$r.prefix}content`]=true
      return a
    }
  }
}
</script>
