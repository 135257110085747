<template>
  <div
    class="color-box"
    :class="{ light: isLightTheme }"
    :style="{ width: totalWidth + 'px' }"
  >
    <div class="color-set">
      <Saturation
        ref="saturation"
        :color="rgbString"
        :hsv="hsv"
        :size="hueHeight"
        @selectSaturation="selectSaturation"
      />
      <Hue
        ref="hue"
        :hsv="hsv"
        :width="hueWidth"
        :height="hueHeight"
        @selectHue="selectHue"
      />
      <Alpha
        ref="alpha"
        :color="rgbString"
        :rgba="rgba"
        :width="hueWidth"
        :height="hueHeight"
        @selectAlpha="selectAlpha"
      />
    </div>
    <div :style="{ height: previewHeight + 'px' }" class="color-show">
      <Preview
        :color="rgbaString"
        :width="previewWidth"
        :height="previewHeight"
      />
    </div>
    <Box name="HEX" :color="modelHex" @inputColor="inputHex" />
    <Box name="RGBA" :color="modelRgba" @inputColor="inputRgba" />
    <Colors
      :colr="rgbaString"
      :colors-default="colorsDefault"
      @selectColor="selectColor"
    />
    <!-- custom options -->
    <slot></slot>
  </div>
</template>

<script>
import { setColorValue, rgb2hex } from './composible'

import Saturation from './Saturation.vue'
import Hue from './Hue.vue'
import Alpha from './Alpha.vue'
import Preview from './Preview.vue'
import Box from './Box.vue'
import Colors from './Colors.vue'

export default {
  components: {
    Saturation,
    Hue,
    Alpha,
    Preview,
    Box,
    Colors,
  },
  emits: ['changeColor'],
  props: {
    color: {
      type: String,
      default: '#DD0FB2',
    },
    theme: {
      type: String,
      default: 'light',
    },
    colorsDefault: {
      type: Array,
      default: () => [
        '#000000',
        '#FFFFFF',
        '#FF1900',
        '#F47365',
        '#FFB243',
        '#FFE623',
        '#6EFF2A',
        '#1BC7B1',
        '#00BEFF',
        '#2E81FF',
        '#5D61FF',
        '#FF89CF',
        '#FC3CAD',
        '#BF3DCE',
        '#8E00A7',
        'rgba(0,0,0,0)',
      ],
    }
  },
  data() {
    return {
      hueWidth: 15,
      hueHeight: 200,
      previewHeight: 30,
      modelRgba: '',
      modelHex: '',
      r: 0,
      g: 0,
      b: 0,
      a: 1,
      h: 0,
      s: 0,
      v: 0,
    }
  },
  computed: {
    isLightTheme() {
      return this.theme === 'light'
    },
    totalWidth() {
      return this.hueHeight + (this.hueWidth + 14) * 2
    },
    previewWidth() {
      return this.totalWidth-20
    },
    rgba() {
      return {
        r: this.r,
        g: this.g,
        b: this.b,
        a: this.a,
      }
    },
    hsv() {
      return {
        h: this.h,
        s: this.s,
        v: this.v,
      }
    },
    rgbString() {
      return `rgb(${this.r}, ${this.g}, ${this.b})`
    },
    rgbaStringShort() {
      return `${this.r}, ${this.g}, ${this.b}, ${this.a}`
    },
    rgbaString() {
      return `rgba(${this.rgbaStringShort})`
    },
    hexString() {
      return rgb2hex(this.rgba, true)
    },
  },
  created() {
    Object.assign(this, setColorValue(this.color))
    this.setText()
  },
  watch:{
    color:function () {
 Object.assign(this, setColorValue(this.color))
    },
    rgba:function () {
this.$emit('changeColor', {
        rgba: this.rgba,
        hsv: this.hsv,
        hex: this.modelHex,
      })
    }
  },
  methods: {
    selectSaturation(color) {
      const { r, g, b, h, s, v } = setColorValue(color)
      Object.assign(this, { r, g, b, h, s, v })
      this.setText()
    },
    selectHue(color) {
      const { r, g, b, h, s, v } = setColorValue(color)
      Object.assign(this, { r, g, b, h, s, v })
      this.setText()
      this.$nextTick(() => {

        this.$refs.saturation.renderColor()

        this.$refs.saturation.renderSlide()
      })
    },
    selectAlpha(a) {
      this.a = a
      this.setText()
    },
    inputHex(color) {
      const { r, g, b, a, h, s, v } = setColorValue(color)
      Object.assign(this, { r, g, b, a, h, s, v })
      this.modelHex = color
      this.modelRgba = this.rgbaStringShort
      this.$nextTick(() => {

        this.$refs.saturation.renderColor()

        this.$refs.saturation.renderSlide()

        this.$refs.hue.renderSlide()
      })
    },
    inputRgba(color) {
      const { r, g, b, a, h, s, v } = setColorValue(color)
      Object.assign(this, { r, g, b, a, h, s, v })
      this.modelHex = this.hexString
      this.modelRgba = color
      this.$nextTick(() => {
        this.$refs.saturation.renderColor()

        this.$refs.saturation.renderSlide()

        this.$refs.hue.renderSlide()
      })
    },
    setText() {
      this.modelHex = this.hexString
      this.modelRgba = this.rgbaStringShort
    },
    selectColor(color) {
      const { r, g, b, a, h, s, v } = setColorValue(color)
      Object.assign(this, { r, g, b, a, h, s, v })
      this.setText()
      this.$nextTick(() => {

        this.$refs.saturation.renderColor()

        this.$refs.saturation.renderSlide()

        this.$refs.hue.renderSlide()
      })
    },
  },
}
</script>

<style lang="scss">
  @import "../../../style/include";
.color-box {
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
  z-index: 1;
  @include light(){
    background: #f7f8f9;
  }

  @include dark(){
    background: #1d2024;
  }

  canvas {
    vertical-align: top;
  }
  .color-set {
    display: flex;
  }
  .color-show {
    margin-top: 8px;
    display: flex;
  }
}
</style>
