<template>
    <div :class="classes">
        <header>
            <r-container class="pa-0">
                <div class="toolbar-content">
                    <menu-laptop :menu="menu"></menu-laptop>
                    <r-spacer></r-spacer>
                    <div v-if="search" class="mt-n5">
                        <r-search-input autofocus closable :label="search_label" :url="search">
                            <template v-slot="{item}">
                                <slot name="search" :item="item"></slot>
                            </template>
                        </r-search-input>
                    </div>
                    <div v-if="logo">
                        <r-img :src="logo" alt="logo" :width="logoW" :height="logoH"></r-img>
                    </div>
                </div>
            </r-container>
        </header>
    </div>
</template>
<script>
    import {toolbar} from "./mixin";
    import MenuLaptop from "./menuLaptop";

    export default {
        components: {MenuLaptop},
        mixins: [toolbar]
    }
</script>