<template>
    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
         :class="classes"
         role="icon"
         :width="width"
         :height="height"
         preserveAspectRatio="xMidYMid meet" :viewBox="viewBox">
    </svg>

</template>

<script>
    import './style.scss'

    export default {
        name: 'index',
        props: {
            viewBox:{type:String,default:'0 0 24 24'},
            width:{type:String,default:'24'},
            height:{type:String,default:'24'},
            disabled: Boolean,
            exact: Boolean
        },
        computed: {
            classes() {
                let a = {
                    'icon-disabled': this.disabled,
                    'exact-icon': this.exact
                }
                a[`${this.$r.prefix}icon`]=true
                return a
            }
        }
    }
</script>
