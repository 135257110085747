<template>
    <canvas ref="chart" :width="width" :height="height">
        Your browser does not support the canvas element.
    </canvas>
</template>

<script>
    export default {
        name: "r-chart",
        props: {
            width: String,
            height: String,
            type: String,
            items: Object,
            options: Object
        },
        data() {
            return {
                Chart: null
            };
        },
        async mounted() {
            await this.add();
        },
        methods: {
            build() {
                new this.Chart(this.$refs.chart, {
                    type: this.type,
                    data: this.items,
                    options: this.options
                });
            },
            async add() {
                await import('./Chart.bundle.min.js')
                this.Chart = Chart
                this.build();
            }
        }
    };
</script>
