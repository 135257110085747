<template>
    <div ref="editorPreview" :class="`${$r.prefix}text-editor`" v-html="$helper.cleanXss(text)"></div>
</template>

<script>
    import './style.scss'

    export default {
        name: "law",
        props: {
            text: String
        },
        data(){
          return{
              id:null,
              imgs:[]
          }
        },
        mounted() {
            this.id=setTimeout(() => {
                this.imgs = this.$refs.editorPreview.querySelectorAll('img')
                for (let i = 0; i < this.imgs.length; i++) {
                    this.imgs[i].addEventListener('click', this.open)
                }
            },1000)
        },
        methods: {
            open(e) {
                window.open(e.target.src.split('?')[0],'_blank')
            }
        },
        beforeUnmount(){
            clearTimeout(this.id)
            for (let i = 0; i < this.imgs.length; i++) {
                    this.imgs[i].removeEventListener('click', this.open)
                }
        }
    };
</script>

<style lang="scss">
    @import "~renusify/style/include";

    img:hover {
        cursor: pointer;
    }
</style>
